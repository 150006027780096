.navbar {
    background-image: linear-gradient(to right bottom, #17471f, #11411c, #0b3b19, #053516, #012f13);
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
}

.navbarNoRoutes {
    background-image: linear-gradient(to right bottom, #17471f, #11411c, #0b3b19, #053516, #012f13);
    height: 80px;
    display: flex;
    justify-content: start;
    align-items: center;
    font-size: 1.2rem;
    padding-left: 75px;
}

.logo {
    max-height: 33px;
}

.navbar-title {
    font-size: 30px;
    font-weight: 400;
}

.no-link {
    color: #fff;
    text-decoration: none;
    display: flex;
    align-items: flex-end;
    column-gap: 15px;
}

.navbar-logo {
    color: #fff;
    justify-self: start;
    margin-left: 20px;
    cursor: pointer;
}

.nav-menu {
    display: grid;
    grid-template-columns: repeat(5, auto);
    grid-gap: 10px;
    list-style: none;
    text-align: center;
    width: 70vw;
    justify-content: end;
}

.nav-links {
    color: #fff;
    text-decoration: none;
    padding: 0.5rem 1rem;
    transition: all 0.2s ease-out;
}

.nav-links:hover {
    background-color: #fff;
    color: #000;
    border-radius: 4px;
}